import React from 'react'
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { useContext } from 'react'

import { LanguageContext } from '../../../lang'
import './index.css'

const yellowLocation = require('../../../images/airport-transport/yellow-location.png')

const PopularCities = () => {
  return (
    <>
    <h1>Placeholder</h1>
      {/* <section id='german-airports'>
        <div className='container mt-5 pt-5'>
          <div className='row mt-5'>
            <h3 className='taxi-to-popular-h3'>Our Popular Cities</h3>
            <p className='taxi-to-popular-p'>
            We transport you everywhere from Eindhoven to the Netherlands, Belgium and Germany.
            And from every city to Eindhoven.
            </p>
          </div>
          <div className='row'>
            <h1 className='german-airports-heading'>Our popular cities in the Netherlands</h1>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <CardBody className='german-airports-card'>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Düsseldorf Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>SBerlijn Tegel Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hamburg Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Düsseldorf Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>SBerlijn Tegel Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hamburg Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Düsseldorf Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>SBerlijn Tegel Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hamburg Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Düsseldorf Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>SBerlijn Tegel Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hamburg Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
            <div className='col-lg-6'>
              <CardBody>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Berlijn Schönefeld Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Stuttgart Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>EuroAirport Basel-Mulhouse-Freiburg
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hanover Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Berlijn Schönefeld Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Stuttgart Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>EuroAirport Basel-Mulhouse-Freiburg
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hanover Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Berlijn Schönefeld Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Stuttgart Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>EuroAirport Basel-Mulhouse-Freiburg
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hanover Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Berlijn Schönefeld Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Stuttgart Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>EuroAirport Basel-Mulhouse-Freiburg
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hanover Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
          </div>
          <div className='row'>
            <h1 className='german-airports-heading'>Popular cities in Belgium</h1>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <CardBody className='german-airports-card'>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
            <div className='col-lg-6'>
              <CardBody>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
          </div>
          <div className='row'>
            <h1 className='german-airports-heading'>Popular cities in Germany</h1>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <CardBody className='german-airports-card'>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Frankfurt Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>München Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Düsseldorf Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>SBerlijn Tegel Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hamburg Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
            <div className='col-lg-6'>
              <CardBody>
                <ul className='german-airports-card-ul'>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Keulen Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Berlijn Schönefeld Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Stuttgart Airport
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>EuroAirport Basel-Mulhouse-Freiburg
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={yellowLocation} alt='yellowLocation' />
                      <span>&nbsp;&nbsp;</span>Hanover Airport
                    </p>
                  </li>
                </ul>
              </CardBody>
            </div>
          </div>
        </div>
  </section>*/}
    </> 
  );
}

export default PopularCities;