import React from 'react';
import Layout from '../components/Layout/layout';
import TwoCitiesComponent from './../components/Two-cities/index';

const TwoCities = () => {
  return ( 
    <>
    <Layout>
      <TwoCitiesComponent />
    </Layout>
    </>
   );
}
 
export default TwoCities;