import React from 'react'
import HeroSection from './Hero-Section/index';
import WhyBookTaxionspot from './Description';
import FixedPrices from './Fixed-Prices';
import ShowRating from '../City/Show-Rating';
import CostEstimate from './../TaxiToEindhoven/Cost-Estimate/index';
import RegularCustomer from './Regular-customer';
import PaymentMethods from './Payment-methods';
import PopularCities from './Popular-Cities';

const TwoCitiesComponent = () => {
  return ( 
    <>
    <h1>Two cities</h1>
      {/* <HeroSection />
      <WhyBookTaxionspot />
      <PaymentMethods />
      <FixedPrices />
      <CostEstimate />
      <RegularCustomer />
      <PopularCities />
      <ShowRating /> */}
    </>
   );
}
 
export default TwoCitiesComponent;